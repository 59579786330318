const routes = {
  index: '/accounts',
  details: '/accounts/:id',
  eligibilityCategories: '/accounts/:id/eligibility-categories',
  policyOverview: '/accounts/:id/policy-overview',
  benefitRules: '/accounts/:id/benefit-rules',
  bankDetails: '/accounts/:id/bank-account',
  paymentMethods: '/accounts/:id/payment-methods',
  reimbursements: '/accounts/reimbursements/:id',
  reimbursementTransferModal: '/accounts/reimbursements/:id/transfer',
  reimbursementTransactionsList: '/accounts/reimbursements/:id/transactions',
  payoutDetails: '/accounts/reimbursements/:id/details/:transaction',
  expenseDetails: '/accounts/expenses/details/:id',
  upcomingEvents: '/accounts/:id/upcoming-events',
  transactions: '/accounts/:id/transactions',
  hsaApplicationForm: '/accounts/:id/application-form/:applicationId',
};

export default routes;
