const pages = {
  all: '/cards/(.*)?',
  index: '/cards',
  details: '/cards/:id',
  updateBillingAddress: '/cards/:id/address',
  create: {
    route: '/(.*)?/cards/create',
    pathname: '/cards/create',
  },
  setCardPin: '/cards/:id/pin',
};

export default pages;
