import { lazy } from 'react';
import { Switch } from 'react-router-dom';
import Route from '@app/components/route';
import routes from '.';

const AccountsPage = lazy(() => import('./accounts-page'));
const TransactionsPage = lazy(() => import('./details/transactions/transactions-page'));
const BenefitDetailsPage = lazy(() => import('./details/account'));
const EligibilityCategories = lazy(() => import('./details/account/components/modals/eligibility-categories.modal'));
const BankDetails = lazy(() => import('./details/account/components/modals/bank-details.modal'));
const UpcomingEvents = lazy(() => import('./details/upcoming-events'));
const BenefitRules = lazy(() => import('./details/account/components/modals/benefit-rules.modal'));
const PolicyOverview = lazy(() => import('./details/account/components/modals/policy-overview.modal'));
const PaymentMethodsModal = lazy(() => import('./details/account/components/modals/payment-methods.modal'));
const ReimbursementsPage = lazy(() => import('./details/reimbursement'));
const AllReimbursementsPage = lazy(() => import('./details/reimbursement/pages/all-reimbursements'));
const PayoutDetailsPage = lazy(() => import('./details/reimbursement/pages/payout-details'));
const TransferModal = lazy(() => import('./details/reimbursement/pages/transfer-modal'));
const TransactionDetailsPage = lazy(() => import('@pages/transactions/details'));
const HSASubmitDocumentsModal = lazy(() => import('./details/account/components/modals/hsa-submit-documents.modal'));

const Accounts = () => (
  <>
    <Switch>
      <Route exact path={routes.transactions} title="Transactions">
        <TransactionsPage />
      </Route>
      <Route exact path={routes.payoutDetails} title="Transaction details">
        <PayoutDetailsPage />
      </Route>
      <Route exact path={routes.expenseDetails} title="Transaction details">
        <TransactionDetailsPage />
      </Route>
      <Route exact path={routes.reimbursementTransactionsList} title="Transaction details">
        <AllReimbursementsPage />
      </Route>
      <Route
        exact
        path={[
          routes.details,
          routes.eligibilityCategories,
          routes.reimbursementTransferModal,
          routes.policyOverview,
          routes.benefitRules,
          routes.bankDetails,
          routes.paymentMethods,
          routes.hsaApplicationForm,
        ]}
        title="Account details"
      >
        <BenefitDetailsPage />
      </Route>
      <Route exact path={routes.reimbursements} title="Reimbursements">
        <ReimbursementsPage />
      </Route>
      <Route exact path={routes.upcomingEvents} title="Upcoming events">
        <UpcomingEvents />
      </Route>
      <Route exact path={routes.index} title="Accounts">
        <AccountsPage />
      </Route>
    </Switch>
    <Switch>
      <Route exact path={routes.reimbursementTransferModal} title="Transfer funds">
        <TransferModal />
      </Route>
      <Route exact path={routes.eligibilityCategories} title="Eligible categories">
        <EligibilityCategories />
      </Route>
      <Route exact path={routes.policyOverview} title="Merchant guidelines">
        <PolicyOverview />
      </Route>
      <Route exact path={routes.benefitRules} title="Benefit rules">
        <BenefitRules />
      </Route>
      <Route exact path={routes.paymentMethods} title="Payment methods">
        <PaymentMethodsModal />
      </Route>
      <Route exact path={routes.bankDetails} title="Benefit rules">
        <BankDetails />
      </Route>
      <Route exact path={routes.hsaApplicationForm} title="Unit application form">
        <HSASubmitDocumentsModal />
      </Route>
    </Switch>
  </>
);

export default Accounts;
