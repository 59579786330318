import customTwMerge from 'react-components/src/utils/twMerge';

const PageContainer = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div
    className={customTwMerge(
      'px-4 lg:px-8 xl:px-12 py-5 lg:py-12 flex flex-col gap-4 bg-grayscale-2 min-h-full',
      className
    )}
  >
    {children}
  </div>
);

export default PageContainer;
